<template>
  <div class="container-register">
    <div class="row">
      <div class="col-md-6">
        <img
          src="../../assets/imgs/login.jpg"
          class="img-fluid"
          alt=""
          width="600"
          height="auto"
        />
      </div>
      <div class="col-md-6" style="background: #fbfbfb">
        <div class="form">
          <div class="row mb-4">
            <div class="col-md-12">
              <h3 class="text-right">{{ "تعديل بيانات المستخدم" | translate }}</h3>
              <hr />
              <p class="text-right">{{ "تأكد من بياناتك جيدا" | translate }}</p>
            </div>
          </div>
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->

          <div class="row g-4">
            <div :class="'col-md-6'">
              <div class="form-group">
                <label>Email</label>
                <input
                  class="form-control"
                  type="email"
                  v-model="authData.email"
                  placeholder="email"
                  required
                  readonly
                />
                <p class="error-msg" v-for="err in emailErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>username</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="authData.username"
                  placeholder="username"
                  required
                />
                <p class="error-msg" v-for="err in usernameErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="authData.phone"
                  placeholder="phone"
                  required
                />
                <p class="error-msg" v-for="err in phoneErrors" :key="err">{{ err }}</p>
              </div>
            </div>
            <div v-if="admin" class="col-md-6">
              <div class="form-group">
                <label>Role & position</label>
                <select class="form-control" v-model="authData.role" :value="roles[0]">
                  <option v-for="(role, key) in roles" :key="key" :value="key">
                    {{ roles[key] }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="!admin" :class="'col-md-6'">
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="authData.password"
                  placeholder="Password"
                  required
                />
                <p class="error-msg" v-for="err in passwordErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <hr />
            <div v-if="changePassword" class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>new Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="authData.newPassword"
                    placeholder="Password confirmation"
                    required
                  />
                  <p class="error-msg" v-for="err in newPasswordErrors" :key="err">
                    {{ err }}
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>New Password confirmation</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="authData.confirm"
                    placeholder="Password confirmation"
                    required
                  />
                  <p class="error-msg" v-for="err in confirmErrors" :key="err">
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <!------- location-fix -------->
              <div class="form-group">
                <label>Location</label>
                <select
                  class="form-control"
                  @change="addLocation"
                  :disabled="$store.getters.auth.role != 2"
                  :value="authData.location[authData.location.length - 1]"
                >
                  <option
                    v-for="location in locations"
                    :key="location._id"
                    :value="location.name"
                  >
                    {{ location.name }}
                  </option>
                </select>
                <p class="error-msg" v-for="err in locationErrors" :key="err">
                  {{ err }}
                </p>
                <div class="chips">
                  <div v-for="(loc, i) in authData.location" :key="i" class="chip">
                    {{ loc }}
                    <i @click="removeLocation(loc)" class="close fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <div class="row justify-content-center">
            <button @click="switchChangePassword" class="btn btn-link btn-block">
              {{
                changePassword ? "عدم تغيير كلمة المرور" : "تغيير كلمة المرور" | translate
              }}
            </button>
          </div>
          <div class="row justify-content-center">
            <div class="col-4 center">
              <loading v-if="loading" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 center">
              <button @click="update" :disabled="!isActive" class="btn btn-success">
                {{ "تحديث البيانات" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.id = this.$route.params.id;
    this.loading = true;
    axios.get("/getUserById/" + this.id).then((res) => {
      this.loading = false;
      if (res.data.err) return;
      this.authData.email = res.data.auth.email;
      this.authData.username = res.data.auth.username;
      this.authData.phone = res.data.auth.phone;
      this.authData.location = res.data.auth.location;
      this.authData.role = res.data.auth.role;
    });
    if (this.$route.path.includes("update-by-admin")) this.admin = true;
    else this.admin = false;
  },
  components: {},
  data() {
    return {
      loading: false,
      admin: false,
      submitted: false,
      changePassword: false,
      id: null,

      authData: {
        email: "",
        username: "",
        phone: "",
        /////// location-fix /////////////
        location: [],
        role: 0,
        password: "",
        newPassword: null,
        confirm: null,
      },
    };
  },
  watch: {
    // mode(val) {},
    ////////
  },
  computed: {
    emailErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.email != "") ok = true;
      if (!ok && !this.submitted) return [];
      let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.authData.email.toLowerCase().match(regExp))
        errors.push("Email is not valid");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.username != "") ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.username.length < 6) errors.push("Username is short");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.phone != "") ok = true;
      if (!ok && !this.submitted) return [];
      var regExp = /[a-z]/i;
      if (this.authData.phone.length < 11) errors.push("Phone is short");
      if (regExp.test(this.authData.phone)) errors.push("Phone is not valid");
      return errors;
    },
    locationErrors() {
      const errors = [];
      let ok = false;
      /////// location-fix /////////////
      if (this.authData.location.length > 0) ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.location.length < 0) errors.push("location is requied");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.password != "") ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.password == "") errors.push("password is requied");
      if (this.authData.password.length < 6) errors.push("password is short");
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.newPassword) ok = true;
      if (!ok && !this.submitted) return [];
      if (!this.authData.newPassword) {
        errors.push("new password is requied");
        return errors;
      }
      if (this.authData.newPassword.length < 6) errors.push("new password is short");
      return errors;
    },
    confirmErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.confirm) ok = true;
      if (!ok && !this.submitted) return [];

      if (!this.authData.newPassword) {
        errors.push("new password confirmation is requied");
        return errors;
      }
      if (this.authData.confirm !== this.authData.newPassword)
        errors.push("Password doesn't match");
      return errors;
    },
    /////////////////////////////////////////////////
    roles() {
      return this.$store.getters.roles;
    },
    locations() {
      return this.$store.getters.locations;
    },
    isActive() {
      let ready = true;
      if (
        this.emailErrors.length > 0 ||
        this.phoneErrors.length > 0 ||
        this.usernameErrors.length > 0 ||
        (!this.admin && this.passwordErrors.length > 0) ||
        (this.changePassword && this.newPasswordErrors.length > 0) ||
        (this.changePassword && this.confirmErrors.length > 0)
      )
        ready = false;
      return ready;
    },
  },
  methods: {
    switchChangePassword() {
      this.changePassword = !this.changePassword;
    },
    ////////////////////////////////////
    update() {
      this.submitted = true;
      if (!this.isActive) return;
      this.loading = true;
      const api = this.admin ? "/updateByAdmin" : "/updateByUser";
      axios.post(`${api}/${this.id}`, this.authData).then((res) => {
        this.loading = false;
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        this.$store.dispatch("writeMessage", "تم تعديل البيانات بنجاح");
        if (this.admin) return;
        this.$store.dispatch("afterSignin", res.data.auth);
      });
    },
    /////// location-fix /////////////
    addLocation(e) {
      const location = this.authData.location;
      if (!location.includes(e.target.value)) location.push(e.target.value);
    },
    removeLocation(loc) {
      if (this.$store.getters.auth.role != 2) return;
      const index = this.authData.location.findIndex((location) => location == loc);
      this.authData.location.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.register-heading {
  h3 {
    font-size: 2rem;
    @media (max-width: 500px) {
      font-size: 1rem;
    }
  }
}
.container-register {
  direction: ltr;
  text-align: left;
  padding: 40px;
  background: rgba(255, 255, 255, 0.898);
  @media (max-width: 500px) {
    padding: 60px 12px;
  }
}
.form-group {
  .error-msg {
    color: #dc3545;
    margin-top: 5px;
    &::before {
      content: "*";
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }
  }
}
</style>
